<template>
    <v-container fluid>
        <v-form @submit.prevent="getTrackingContainers('search')">
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat light>
                    <v-card-text class="pa-0">
                        <v-row>
                            <v-col cols="12">
                                <v-subheader class="headline">{{ $t("menu_tracking_container") }}</v-subheader>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="8">
                                <ValidationProvider ref="tracking" rules="required" v-slot="{ errors, valid }">
                                    <v-autocomplete v-model="tracking" :items="trackingItems"
                                                    :error="!valid" :error-messages="errors"
                                                    :search-input.sync="trackingSearching"
                                                    item-text="name" item-value="id"
                                                    prepend-icon="mdi-arrow-decision"
                                                    :loading="loadingTrackings" :disabled="loading"
                                                    @click="clearTrackings"
                                                    :no-data-text="trackingSearching ? $t('nothing_found_by', {search: trackingSearching}) : $t('nothing_found_tracking_name')"
                                                    :label="$t('tracking_name')"
                                                    outlined rounded dense color="primary"
                                                    return-object clearable autocomplete="off">
                                    </v-autocomplete>
                                </ValidationProvider>
                            </v-col>

                            <v-col cols="12" sm="4">
                                <v-select :value="perPage" :items="perPageItems"
                                          :disabled="loading" :label="$t('items_per_page')"
                                          prepend-icon="mdi-counter" outlined rounded dense
                                          @input="perPage = options.itemsPerPage = Number($event)">
                                </v-select>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-0">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-btn :disabled="invalid || loading"
                                       :block="$vuetify.breakpoint.xsOnly"
                                       color="primary" @click="UploadTrackingContainers">
                                    <v-icon class="mr-2">mdi-microsoft-excel</v-icon>
                                    {{ $t("save_to_excel") }}
                                </v-btn>
                            </v-col>
                            <v-col cols="12" sm="6" class="text-right">
                                <v-btn type="submit"
                                       :disabled="invalid || loading"
                                       :block="$vuetify.breakpoint.xsOnly"
                                       color="primary">
                                    {{ $t("search") }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
        <v-card flat light>
            <v-card-text class="px-0">
                <v-data-table :headers="headers" :items="trackingContainerItems"
                              :options.sync="options" :page.sync="page" :items-per-page="perPage"
                              :server-items-length="totalTrackingContainers"
                              :sort-by.sync="sortBy" :sort-desc.sync="sortDir"
                              :loading="loading" :locale="lang"
                              @page-count="pageCount = options.pageCount = Number($event)"
                              :loading-text="$t('loading_please_wait')" class="elevation-1"
                              hide-default-footer>
                    <template v-slot:item.name="{ item }">
                        <div :class="item.deleted ? 'red--text' : ''">
                            {{ item.name }}
                            {{ item.deleted ? " (" + $t("deleted") + ")" : "" }}
                        </div>
                    </template>
                    <template v-slot:item.paid="{ item }">
                        {{ item.paid ? $t("yes") : $t("no") }}
                    </template>
                    <template v-slot:item.date_of_arrival_at_the_border="{ item }">
                        {{
                            item.date_of_arrival_at_the_border ? $moment(item.date_of_arrival_at_the_border).format("YYYY-MM-DD") : ""
                        }}
                    </template>
                    <template v-slot:item.arrival_date_at_destination="{ item }">
                        {{
                            item.arrival_date_at_destination ? $moment(item.arrival_date_at_destination).format("YYYY-MM-DD") : ""
                        }}
                    </template>
                    <template v-slot:item.date_operation="{ item }">
                        {{ item.date_operation ? $moment(item.date_operation).format("YYYY-MM-DD") : "" }}
                    </template>
                    <template v-slot:item.date_of_deployment="{ item }">
                        {{ item.date_of_deployment ? $moment(item.date_of_deployment).format("YYYY-MM-DD") : "" }}
                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-tooltip bottom v-if="item.id">
                            <template v-slot:activator="{ on }">
                                <v-btn icon v-on="on" class="mr-2"
                                       @click="showDetailsTrackingContainer(item)">
                                    <v-icon>mdi-card-text-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t("details") }}</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-pagination v-show="totalTrackingContainers > perPage"
                              v-model="page" :total-visible="totalVisiblePag"
                              :length="pageCount" :disabled="loading">
                </v-pagination>
            </v-card-actions>
        </v-card>
        <v-dialog v-if="dialogDetails" v-model="dialogDetails" fullscreen
                  hide-overlay transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-toolbar-title>{{ $t("details") }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon dark @click="cancelDetails()">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text class="pa-0">
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-col cols="12" class="py-1 text-right"
                            ><b> {{ $t("document_in_1C") }}:</b>
                                {{ detailsItems.uuid ? detailsItems.uuid : $t("no_data")}}
                            </v-col>
                            <v-col cols="12" class="py-1 text-right">
                                <b> {{ $t("client") }}:</b>
                                {{ detailsItems.client.name ? detailsItems.client.name + " ( " + detailsItems.client.login + " )" : $t("no_data") }}
                            </v-col>
                            <v-col cols="12" class="py-1 text-right">
                                <b> {{ $t("application_number") }}:</b>
                                {{ detailsItems.application_number ? detailsItems.application_number : $t("no_data") }}
                            </v-col>
                            <v-col cols="12" class="py-1 text-right">
                                <b> {{ $t("application_date") }}:</b>
                                {{ detailsItems.application_date ? $moment(detailsItems.application_date).format("YYYY-MM-DD") : $t("no_data") }}
                            </v-col>
                            <v-col cols="12" class="py-1 text-right">
                                <b> {{ $t("date_operation") }}:</b>
                                {{ detailsItems.date_operation ? $moment(detailsItems.date_operation).format("YYYY-MM-DD") : $t("no_data") }}
                            </v-col>
                            <v-col cols="12" class="py-1 text-right">
                                <b> {{ $t("cargo_code") }}:</b>
                                {{ detailsItems.kzh_code ? detailsItems.kzh_code : $t("no_data") }}
                            </v-col>
                            <v-col cols="12" class="py-1 text-right">
                                <b> {{ $t("departures") }} :</b>
                                {{ detailsItems.departures ? detailsItems.departures : $t("no_data") }}
                            </v-col>
                            <v-col cols="12" class="py-1 text-right">
                                <b>{{ $t("date_of_arrival_at_the_border") }} :</b>
                                {{ detailsItems.date_of_arrival_at_the_border ? $moment(detailsItems.date_of_arrival_at_the_border).format("YYYY-MM-DD") : $t("no_data") }}
                            </v-col>
                            <v-col cols="12" class="py-1 text-right">
                                <b> {{ $t("destination") }} :</b>
                                {{ detailsItems.destination ? detailsItems.destination : $t("no_data") }}
                            </v-col>
                            <v-col cols="12" class="py-1 text-right">
                                <b> {{ $t("container_number") }} :</b>
                                {{ detailsItems.container_number ? detailsItems.container_number : $t("no_data") }}
                            </v-col>
                            <v-col cols="12" class="py-1 text-right">
                                <b> {{ $t("container_type") }} :</b>
                                {{ detailsItems.container_type ? detailsItems.container_type : $t("no_data") }}
                            </v-col>
                            <v-col cols="12" class="py-1 text-right">
                                <b> {{ $t("invoice_number") }} :</b>
                                {{ detailsItems.dispatch_number ? detailsItems.dispatch_number : $t("no_data") }}
                            </v-col>
                            <v-col cols="12" class="py-1 text-right">
                                <b> {{ $t("overload_date") }} :</b>
                                {{ detailsItems.overload_date ? $moment(detailsItems.overload_date).format("YYYY-MM-DD") : $t("no_data") }}
                            </v-col>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-col cols="12" class="py-1">
                                <b> {{ $t("car_number") }} :</b>
                                {{ detailsItems.car_number ? detailsItems.car_number : $t("no_data") }}
                            </v-col>
                            <v-col cols="12" class="py-1">
                                <b> {{ $t("car_number_knr") }} :</b>
                                {{ detailsItems.car_number_knr ? detailsItems.car_number_knr : $t("no_data") }}
                            </v-col>
                            <v-col cols="12" class="py-1">
                                <b> {{ $t("operations") }} :</b>
                                {{ detailsItems.operations ? detailsItems.operations : $t("no_data") }}
                            </v-col>
                            <v-col cols="12" class="py-1">
                                <b> {{ $t("status") }} :</b>
                                {{ detailsItems.status ? detailsItems.status : $t("no_data") }}
                            </v-col>
                            <v-col cols="12" class="py-1">
                                <b>
                                {{$t("date_of_arrival_at_the_destination_station")}} :</b>
                                {{detailsItems.arrival_date_at_destination? $moment(detailsItems.arrival_date_at_destination).format("YYYY-MM-DD") : $t("no_data") }}
                            </v-col>
                            <v-col cols="12" class="py-1">
                                <b>{{ $t("distance_to_destination_station") }} :</b>
                                {{ detailsItems.distance_to_destination ? detailsItems.distance_to_destination : $t("no_data") }}
                            </v-col>
                            <v-col cols="12" class="py-1">
                                <b> {{ $t("cargo_weight") }} :</b>
                                {{ detailsItems.weight && detailsItems.weight > 0 ? detailsItems.weight : $t("no_data") }}
                            </v-col>
                            <v-col cols="12" class="py-1">
                                <b> {{ $t("ET_CIS_cargo_code") }} :</b>
                                {{ detailsItems.etsng ? detailsItems.etsng : $t("no_data") }}
                            </v-col>
                            <v-col cols="12" class="py-1">
                                <b> {{ $t("condition") }} :</b>
                                {{ detailsItems.condition ? detailsItems.condition : $t("no_data") }}
                            </v-col>
                            <v-col cols="12" class="py-1">
                                <b> {{ $t("delivered") }} :</b>
                                {{ detailsItems.paid == true ? $t("yes") : $t("no") }}
                            </v-col>
                            <v-col cols="12" class="py-1">
                                <b> {{ $t("kzh_code") }} :</b>
                                {{ detailsItems.subcode.subcode1 ? detailsItems.subcode.subcode1 : $t("no_data") }}
                            </v-col>
                            <v-col cols="12" class="py-1">
                                <b> {{ $t("date_of_sending") }} :</b>
                                {{ detailsItems.date_sending ? $moment(detailsItems.date_sending).format("YYYY-MM-DD") : $t("no_data") }}
                            </v-col>
                            <v-col cols="12" class="py-1">
                                <b>{{ $t("date_of_shipment_in_the_CIS") }} :</b>
                                {{ detailsItems.date_sending_sng ? $moment(detailsItems.date_sending_sng).format("YYYY-MM-DD") : $t("no_data") }}
                            </v-col>
                        </v-col>
                        <v-col class="py-0" cols="12" sm="2"></v-col>
                        <v-col class="py-0" cols="12" sm="8">
                            <template>
                                <v-timeline align-top :dense="$vuetify.breakpoint.smAndDown">
                                    <v-timeline-item
                                        v-for="(item, i) in detailsItems.application_operations"
                                        :key="i" color="primary"
                                        icon="mdi-chevron-down-circle-outline" fill-dot>
                                        <v-card color="primary" dark>
                                            <v-card-title class="title">
                                                {{ $t("update_date") }}:
                                                {{ item.date_of_deployment ? $moment(item.date_of_deployment).format("YYYY-MM-DD HH:MM") : $t("no_data") }}
                                            </v-card-title>
                                            <v-card-text class="white text--primary">
                                                <p style="line-height: 25px">
                                                    <b>{{$t("car_number") }} :</b>
                                                    {{ item.car_number ? item.car_number : $t("no_data") }}
                                                    <br/>
                                                    <b>{{ $t("distance_to_destination_station")}} :</b>
                                                    {{ item.distance_to_destination ? item.distance_to_destination : $t("no_data") }}
                                                    <br/>
                                                    <b>{{ $t("status") }} Статус:</b>
                                                    {{item.status ? item.status : $t("no_data") }}
                                                    <br/>
                                                    <b>{{$t("operations") }} :</b>
                                                    {{item.operations ? item.operations : $t("no_data") }}
                                                    <br/>
                                                    <b>{{$t("date_operation") }}</b>:
                                                    {{
                                                        item.date_operation ? $moment(item.date_operation).format("YYYY-MM-DD") : $t("no_data")
                                                    }}
                                                </p>
                                            </v-card-text>
                                        </v-card>
                                    </v-timeline-item>
                                </v-timeline>
                            </template>
                        </v-col>
                        <v-col cols="12" sm="2"></v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import debounce from "lodash/debounce"
import {ValidationObserver, ValidationProvider} from "vee-validate"
import {mapGetters} from "vuex"

export default {
    name: "TrackingContainers",
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            sortBy: "id",
            sortDir: true,
            loading: false,
            dialogDetails: false,
            detailsItems: [],
            options: {},
            page: 1,
            pageCount: 0,
            perPage: 5,
            totalVisiblePag: 5,
            totalTrackingContainers: 0,
            trackingContainerItems: [],
            tracking: null,
            trackingItems: [],
            loadingTrackings: false,
            trackingSearching: null,
            headers: [
                {
                    text: this.$t("date_of_deployment"),
                    align: "center",
                    sortable: true,
                    value: "date_of_deployment",
                },
                {
                    text: this.$t("car_number"),
                    align: "center",
                    sortable: false,
                    value: "car_number",
                },
                {
                    text: this.$t("car_number_knr"),
                    align: "center",
                    sortable: false,
                    value: "car_number_knr",
                },
                {
                    text: this.$t("application_number"),
                    align: "center",
                    sortable: false,
                    value: "application_number",
                },
                {
                    text: this.$t("kzh_code"),
                    align: "center",
                    sortable: false,
                    value: "kzh_code",
                },
                {
                    text: this.$t("departures"),
                    align: "center",
                    sortable: false,
                    value: "departures",
                },
                {
                    text: this.$t("destination"),
                    align: "center",
                    sortable: false,
                    value: "destination",
                },
                {
                    text: this.$t("date_of_arrival_at_the_border"),
                    align: "center",
                    sortable: false,
                    value: "date_of_arrival_at_the_border",
                },
                {
                    text: this.$t("container_number"),
                    align: "center",
                    sortable: false,
                    value: "container_number",
                },
                {
                    text: this.$t("container_type"),
                    align: "center",
                    sortable: false,
                    value: "container_type",
                },
                {
                    text: this.$t("dispatch_number"),
                    align: "center",
                    sortable: false,
                    value: "dispatch_number",
                },
                {
                    text: this.$t("operations"),
                    align: "center",
                    sortable: false,
                    value: "operations",
                },
                {
                    text: this.$t("status"),
                    align: "center",
                    sortable: false,
                    value: "status",
                },
                {
                    text: this.$t("arrival_date_at_destination"),
                    align: "center",
                    sortable: false,
                    value: "arrival_date_at_destination",
                },
                {
                    text: this.$t("date_operation"),
                    align: "center",
                    sortable: false,
                    value: "date_operation",
                },
                {
                    text: this.$t("details"),
                    align: "center",
                    value: "action",
                    sortable: false,
                    width: 120,
                },
            ],
        }
    },
    computed: {
        ...mapGetters(["lang", "itemsPerPage", "perPageItems"]),
    },
    watch: {
        options: {
            handler() {
                this.getTrackingContainers()
            },
            deep: false,
        },
        trackingSearching: debounce(function (val) {
            if (val && !this.tracking) {
                this.getTrackings(val)
            }
        }, 500),
    },
    mounted() {
        this.options.itemsPerPage = this.perPage = this.itemsPerPage
    },
    methods: {
        clearTrackings() {
            if (!this.tracking) {
                this.trackingItems = []
            }
        },
        async getTrackings(str) {
            if (str) {
                this.loadingTrackings = true
                let params = {}
                params.filter = "search"
                if (str !== "undefined") {
                    params.tracking_name = str
                }
                await this.$http
                    .get("client/tracking", {
                        params: params,
                    })
                    .then((res) => {
                        this.trackingItems = res.body.data
                    })
                    .catch((err) => {
                        this.trackingItems = []
                        this.$toastr.error(
                            this.$t("failed_to_get_list_trackings")
                        )
                    })
                    .finally((end) => {
                        this.loadingTrackings = false
                    })
            }
        },
        async showDetailsTrackingContainer(item) {
            this.loading = true
            await this.$http
                .get(`client/cargo_tracking/${item.id}`)
                .then((res) => {
                    this.detailsItems = res.body.data
                    this.dialogDetails = true
                })
                .catch((err) => {
                    this.trackingContainerItems = []
                    this.$toastr.error(
                        this.$t("failed_to_get_list_tracking_containers")
                    )
                })
                .finally((end) => {
                    this.loading = false
                })
        },
        async UploadTrackingContainers() {
            this.loading = true
            const {sortBy, sortDesc, page, itemsPerPage} = this.options
            let params = {}
            if (sortBy[0] !== undefined) {
                params.sortBy = sortBy[0]
            }
            if (sortDesc[0] !== undefined) {
                params.sortDir = sortDesc[0] ? "asc" : "desc"
            }
            if (page !== undefined) {
                params.page = page
            }
            if (itemsPerPage !== undefined) {
                params.perPage = itemsPerPage
            }

            params.upload = 1
            params.language = this.lang

            if (
                this.tracking !== undefined &&
                this.tracking != null &&
                this.tracking !== ""
            ) {
                params.tracking = this.tracking.id
            }

            await this.$http
                .get("client/cargo_tracking", {
                    params: params,
                })
                .then((res) => {
                    this.saveToFile(res.body.data.file)
                })
                .catch((err) => {
                    this.$toastr.error(this.$t("failed_to_upload_file"))
                })
                .finally((end) => {
                    this.loading = false
                })
        },
        async getTrackingContainers(type) {
            if (!this.tracking) return
            this.loading = true
            const {sortBy, sortDesc, page, itemsPerPage} = this.options
            let params = {}
            if (sortBy[0] !== undefined) {
                params.sortBy = sortBy[0]
            }
            if (sortDesc[0] !== undefined) {
                params.sortDir = sortDesc[0] ? "asc" : "desc"
            }
            if (page !== undefined) {
                params.page = page
            }
            if (type === 'search') {
                params.page = 1
            }
            if (itemsPerPage !== undefined) {
                params.perPage = itemsPerPage
            }

            params.tracking = this.tracking.id
            params.language = this.lang

            await this.$http
                .get("client/cargo_tracking", {
                    params: params,
                })
                .then((res) => {
                    this.trackingContainerItems = res.body.data
                    if (res.body.meta) {
                        this.page = res.body.meta.current_page
                        this.totalTrackingContainers = res.body.meta.total
                        this.pageCount = res.body.meta.last_page
                    }
                })
                .catch((err) => {
                    this.trackingContainerItems = []
                    this.totalTrackingContainers = 0
                    this.$toastr.error(this.$t("failed_to_get_list_tracking_containers"))
                })
                .finally((end) => {
                    this.loading = false
                })
        },
        cancelDetails() {
            this.dialogDetails = false
        },
        saveToFile(url) {
            let link = document.createElement("a")
            link.setAttribute("href", url)
            link.style.visibility = "hidden"
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        },
    },
}
</script>
